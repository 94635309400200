import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { UserContext } from "../contexts/user";

export default function AddressForm() {
  const [state, dispatch] = React.useContext(UserContext);
  return (
    <React.Fragment
    >
      <Typography variant="h6" gutterBottom
      sx={{
        color: "#dddddd",
        fontWeight: 'bolder',
        fontFamily: "Arial",
        paddingBottom: 3
      }}>
        Get CodingX Pro
      </Typography>
      <Grid container spacing={3}
      >
        <Grid item xs={12} >
          <TextField
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="user@mail.com"
            variant="outlined"
            onChange={(e) => {
              dispatch({ email: e.target.value });
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
